<!-- A设备管理-巡检管理-点检工单 -->
<template>
    <div class="page_container height_100">
        <div class="bgc_FFFFFF" style="height:92%">
            <div class="box_head1">
                <span>点检工单</span>
                <div class="flex align_center mg_left_50 fn_size14">
                <div>班组：</div>
                <el-select v-model="inspectionGroupArValue" placeholder="请选择" class="divMargin width_150" clearable>
                    <el-option v-for="(item,index) in inspectionGroupAr" :key="index" :label="item.description"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div>工单状态：</div>
                <el-select v-model="orderStatusValue" placeholder="请选择" class=" width_150" clearable>
                    <el-option v-for="item in orderStatus" :key="item.value" :label="item.description"
                        :value="item.value">
                    </el-option>
                </el-select>
                <el-button type="primary" class="mg_left_10" size="medium"
                    @click="query">查询
                </el-button>
                <!-- <div>名称：</div>
                <el-input v-model="input" class="divMargin width_150"></el-input>
                <div>使用部门：</div>
                <el-input v-model="input" class="divMargin width_150"></el-input> -->
            </div>
            </div>
        
        
            <!-- <div class="flex justify_between pdt_20 fn_size16">
                <div class="flex">
                    <el-button type="primary" class="width_120" style=" background: #E4649D;border: none;"
                        @click="receivingOrders">接单
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #5781EA;border: none;"
                        @click="implementOperation">执行
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #FC8A69;border: none;"
                        @click="temporarilyPart">暂离
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #5781EA;border: none;"
                        @click="temporarilyPartEnd">暂离结束
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #E3AA3C;border: none;"
                        @click="orderStatement">结单
                    </el-button>
                </div>
                <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出
                </el-button>
            </div> -->
            <!-- <div class="flex justify_between pdt_20 fn_size16">
                                <div class="flex">
                    <el-button type="primary" icon="el-icon-delete"
                        class="width_140 mg_left_10 bgc_F75753 buttonBorder" style="background-color: #F75753;">删除</el-button>
                </div>
                                <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出
                </el-button>
                <el-button type="primary" class="width_110 " icon="el-icon-circle-plus-outline"
                    style=" background: #03CABE;border: none;" @click="gotoAddRepair">新增工单
                </el-button>
            </div> -->
            <div class="bgc_FFFFFF pdl30 pdr30  height_100">   
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" height="92%">
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column type="index" label="序号" width="80"></el-table-column>
                    <!-- <el-table-column prop="ID" label="id" width=""></el-table-column> -->
                    <el-table-column prop="orderID" label="工单编号" width="" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="projectID" label="项目编号" width=""></el-table-column> -->
                    <el-table-column prop="orderType_name" label="点检类型" width=""></el-table-column>
                    <el-table-column prop="orderName" label="工单名称" width=""></el-table-column>
                    <el-table-column prop="orderStatus_name" label="工单状态" width=""></el-table-column>
                    <el-table-column prop="" label="是否异常" width="">
                        <template slot-scope="scope">
                            <span>{{scope.row.isAbnormal?'是':'否'}}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="execLenM" label="执行时间长度" width=""></el-table-column> -->
                    <!-- <el-table-column prop="oprPerson" label="记录人" width=""></el-table-column> -->
                    <!-- <el-table-column prop="oprTime" label="记录时间" width="" :formatter="formatDate"></el-table-column> -->
                    <el-table-column prop="startTime" label="开始时间" width="" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="endTime" label="结束时间" width="" :formatter="formatDate"></el-table-column>
                    <!-- <el-table-column prop="" label="是否结单逾期" width="">
                        <template slot-scope="scope">
                            <span>{{scope.row.isEndOverdue}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="overdueReason" label="逾期理由" width=""></el-table-column> -->
                    <!-- <el-table-column prop="assignEndTime" label="指定点检结单时间" width="" :formatter="formatDate"> -->
                    <!-- </el-table-column>
                    <el-table-column prop="execGroup" label="点检班组" width=""></el-table-column>
                    <el-table-column prop="execPerson" label="点检人" width=""></el-table-column> -->
                    <!-- <el-table-column prop="takeTime" label="接单时间" width="" :formatter="formatDate"></el-table-column> -->
                    <el-table-column prop="orderLocScope" label="点检位置范围" width=""></el-table-column>
                    <!-- <el-table-column prop="remarks" label="备注" width=""></el-table-column> -->
                    <el-table-column label="详细" width="">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="gotoFaultDetails(scope.row.ID)">详细</span>
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="240">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="gotoEdit(scope.row.ID)" v-if="scope.row.orderStatus!=5">
                                修改
                            </el-button>
                            <!-- <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="delTableData(scope.row.ID)">
                                删除
                            </el-button> -->
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="scope.row.orderStatus==1"
                                @click="receivingOrders(scope.row.ID)">接单
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="scope.row.orderStatus==2"
                                @click="implementOperation(scope.row.ID)">执行
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="scope.row.orderStatus==3"
                                @click="temporarilyPart(scope.row.ID)">暂离
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="scope.row.orderStatus==4"
                                @click="temporarilyPartEnd(scope.row.ID)">暂离结束
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="scope.row.orderStatus==3"
                                @click="orderStatement(scope.row.ID)">结单
                            </el-button>


                        </template>
                    </el-table-column>
                </el-table>
            <!-- 页码 -->
            <!-- <div class="flex justify_between align_start">
                <div class="color_808080 fn_size14 mg_top_19">当前1-10 总记录：123,456</div>
            </div> -->
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div> 
            <!-- 第二部分 -->
            <!--             <div class="bgc_E2F0FE flex align_end mg_top_24 height_64">
                <div class="tab_box mg_left_30 " :class="tabIndex==0?'tab_box_active':''" @click="handleChange(0)">巡检项明细
                </div>
            </div>
            <div class="mg_top_31 flex justify_start">
                <el-select v-model="value" placeholder="全部" class="mg_right_10">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="mg_top_22">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" @selection-change="handleSelectionChange" max-height="500"
                    :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="date" label="标准名称" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="date" label="开始时间" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="name" label="结束时间" width=""></el-table-column>
                    <el-table-column prop="name" label="异常项目数" width=""></el-table-column>
                    <el-table-column prop="name" label="漏检项目数" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="date" label="设备编号" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="date" label="设备名称" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="name" label="规格型号" width=""></el-table-column>
                    <el-table-column prop="name" label="跳过扫码原因" width=""></el-table-column>
                    <el-table-column prop="name" label="跳过原因" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"  @click="dialogTableVisible = true">详细</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div> -->
            <!-- dialog -->
            <el-dialog :visible.sync="dialogTableVisible" top='10vh' width="40%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">点巡检</div>
                <!-- table -->
                <el-table :data="gridData" class="dialog__body">
                    <el-table-column property="use_dept" label="巡检名称"></el-table-column>
                    <el-table-column property="belong_dept" label="上限"></el-table-column>
                    <el-table-column property="equipment_model" label="下限"></el-table-column>
                    <el-table-column property="equipment_num" label="巡检记录"></el-table-column>
                    <el-table-column property="equipment_name" label="巡检结果"></el-table-column>
                    <el-table-column property="equipment_num" label="是否异常"></el-table-column>
                    <el-table-column property="equipment_name" label="备注"></el-table-column>
                </el-table>
                <!-- 按钮 -->
                <div class="flex justify_center pdb_20 pdt_20">
                    <el-button type="primary" @click="dialogTableVisible = false">确定</el-button>
                    <el-button type="primary" @click="dialogTableVisible = false">关闭</el-button>
                </div>
            </el-dialog>
            <!-- 页码 -->
            <!--             <div class="flex justify_between align_start">
                <div class="color_808080 fn_size14 mg_top_19">当前1-10 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div> -->

        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        inspectionOrderQueryLimit,
        inspectionOrderDelete,
        inspectionOrderTake,
        inspectionOrderExecute,
        inspectionOrderPause,
        inspectionOrderContinue,
        inspectionOrderInspectFinish,

        maintainOrderStatusQuery,
        inspectionGroupQuery, //点检班组筛选
        inspectionOrderTypeQuery, //点检工单类型
        inspectionOrderStatusQuery
    } from "@/api/api_deviceManagement";
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                tabIndex: 0,
                value: '',
                input: '',
                dialogTableVisible: false,
                //分页筛选相关变量
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                //----------------

                //删除操作所需的相关变量
                deleteNum: '',
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
                tableData: [],
                multipleSelection: [],
                gridData: [],
                userID: '',

                orderStatusValue: '',
                orderStatus: [],
                inspectionGroupArValue: '',
                inspectionGroupAr: []
            }
        },
        mounted() {
            //页面刷新即调用分页筛选接口，刷新页面数据
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            this.inspectionOrderQueryLimit();
            this.maintainOrderStatusQuery();
            this.inspectionGroupQuery();
        },
        methods: {
            async maintainOrderStatusQuery() {
                const res = await inspectionOrderStatusQuery();
                this.orderStatus = res.data

            },
            async inspectionGroupQuery() {
                var param = {
                    userID: this.userID,
                }
                const res = await inspectionGroupQuery(param);
                this.inspectionGroupAr = res.data
            },
            //新增
            //点击‘新增’按钮 触发的方法
            gotoAddRepair() {
                this.$router.push({
                    path: '/deviceManagement/patrolManagement/patrolRecord/addPatrolRecord',

                })
            },
            // handleSelectionChange(val) {
            //     // console.log(val)
            //     this.multipleSelection = val;
            // },
            handleChange(id) {
                this.tabIndex = id;
            },
            //详细
            gotoFaultDetails(id) {
                // 
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/patrolManagement/patrolRecord/patrolRecordDetails',
                })
            },
            // 修改
            gotoEdit(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/patrolManagement/patrolRecord/modifyPatrolRecord',
                })
            },
            query() {
                this.currentPage = 1;
                this.inspectionOrderQueryLimit();
            },

            //分页筛选
            async inspectionOrderQueryLimit() {
                //封装参数
                var param = {
                    //ID:'',
                    userID: this.userID,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage
                };
                if (this.inspectionGroupArValue != null && this.inspectionGroupArValue.toString() != '') {
                    param.execGroup = this.inspectionGroupArValue;
                }
                if (this.orderStatusValue != null && this.orderStatusValue.toString() != '') {
                    param.orderStatus = this.orderStatusValue;
                }
                const res = await inspectionOrderQueryLimit(param);

                this.tableData = res.rows;
                this.pageQuantity = res.pageQuantity;
            },
            //点击页码触发分页查询
            handleCurrentChange(val) {
                this.currentPage = val;
                //调用分页查询
                this.inspectionOrderQueryLimit();
            },

            //删除
            //点击删除按钮触发的方法
            delTableData(id) {
                this.deleteNum = id;

                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //调用删除方法
                    this.inspectionOrderDelete();
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            //删除方法
            async inspectionOrderDelete() {
                //封装参数
                var param = {
                    ID: this.deleteNum
                };
                // console.log(param)
                const res = await inspectionOrderDelete(param);
                console.log(res)
                if (res && res.data && res.data.result) {
                    // this.$message({
                    //     message: res.message,
                    //     type: '点检工单删除成功'
                    // });
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    //删除成功后，调用分页筛选方法，刷新页面数据
                    this.inspectionOrderQueryLimit();
                } else {
                    this.$message.error(res.message);
                    // this.$message({
                    //     showClose: true,
                    //     message: res.message,
                    //     type: '点检工单删除失败'
                    // });
                }
            },
            // 接单--开始
            receivingOrders(id) {
                // if (this.multipleSelection.join(',') == '') {
                //     this.$message({
                //         message: '警告：请选择需要接单的数据！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                // if (this.multipleSelection.length > 1) {
                //     this.$message({
                //         message: '警告：请选择一条需要接单的数据！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.$confirm('此操作为接单操作, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.inspectionOrderTake(id);
                }).catch(() => {});
            },
            async inspectionOrderTake(id) {
                var param = {
                    ID: id
                };
                // console.log(param)
                const res = await inspectionOrderTake(param);
                if (res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.inspectionOrderQueryLimit();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 执行--开始
            implementOperation(id) {
                // if (this.multipleSelection.join(',') == '') {
                //     this.$message({
                //         message: '警告：请选择需要执行的工单！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                // if (this.multipleSelection.length > 1) {
                //     this.$message({
                //         message: '警告：请选择一条需要执行的工单！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.$confirm('此操作为执行工单操作, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.inspectionOrderExecute(id);
                }).catch(() => {});
                // this.maintainOrderExecuteTypeQuery();
            },
            async inspectionOrderExecute(id) {
                var param = {
                    ID: id
                };
                // console.log(param)
                const res = await inspectionOrderExecute(param);
                if (res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.inspectionOrderQueryLimit();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 暂离--开始
            temporarilyPart(id) {
                // if (this.multipleSelection.join(',') == '') {
                //     this.$message({
                //         message: '警告：请选择需要暂离的工单',
                //         type: 'warning'
                //     });
                //     return
                // }
                // if (this.multipleSelection.length > 1) {
                //     this.$message({
                //         message: '警告：请选择一条需要暂离的工单！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.$confirm('此操作为暂离工单操作, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.inspectionOrderPause(id);
                }).catch(() => {});
            },
            async inspectionOrderPause(id) {
                var param = {
                    ID: id
                };
                // console.log(param)
                const res = await inspectionOrderPause(param);
                if (res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.inspectionOrderQueryLimit();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 暂离结束--开始
            temporarilyPartEnd(id) {
                // if (this.multipleSelection.join(',') == '') {
                //     this.$message({
                //         message: '警告：请选择需要暂离结束的工单',
                //         type: 'warning'
                //     });
                //     return
                // }
                // if (this.multipleSelection.length > 1) {
                //     this.$message({
                //         message: '警告：请选择一条需要暂离结束的工单！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.$confirm('此操作为暂离结束工单操作, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.inspectionOrderContinue(id);
                }).catch(() => {});
            },
            async inspectionOrderContinue(id) {
                var param = {
                    ID: id
                };
                // console.log(param)
                const res = await inspectionOrderContinue(param);
                if (res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.inspectionOrderQueryLimit();
                } else {
                    this.$message.error(res.message);
                }
            },
            // 保养工单结单--开始
            orderStatement(id) {
                // if (this.multipleSelection.join(',') == '') {
                //     this.$message({
                //         message: '警告：请选择需要结单的工单',
                //         type: 'warning'
                //     });
                //     return
                // }
                // if (this.multipleSelection.length > 1) {
                //     this.$message({
                //         message: '警告：请选择一条需要结单的工单！！！',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.$confirm('此操作为结单操作, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.inspectionOrderInspectFinish(id);
                }).catch(() => {});
            },
            async inspectionOrderInspectFinish(id) {
                var param = {
                    ID: id
                };
                // console.log(param)
                const res = await inspectionOrderInspectFinish(param);
                if (res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.inspectionOrderQueryLimit();
                } else {
                    this.$message.error(res.message);
                }
            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }

    .table_h {
        height: 590px;
    }
</style>
<style>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>